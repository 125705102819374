<template>
  <div>
    <v-form autocomplete="off" @submit.prevent="save()" ref="form">
      <v-text-field
        :label="$t('users.fields.username.description') + ' *'"
        :placeholder="$t('users.fields.username.title')"
        v-model="form.username"
        @input="delayTouch($v.form.username)"
        @blur="$v.form.username.$touch"
        :error-messages="usernameErrors"
      ></v-text-field>
      <v-text-field
        :label="$t('users.fields.firstName.description') + ' *'"
        :placeholder="$t('users.fields.firstName.title')"
        v-model="form.firstName"
        @input="delayTouch($v.form.firstName)"
        @blur="$v.form.firstName.$touch"
        :error-messages="firstNameErrors"
      ></v-text-field>
      <v-text-field
        :label="$t('users.fields.lastName.description') + ' *'"
        :placeholder="$t('users.fields.lastName.title')"
        v-model="form.lastName"
        @input="delayTouch($v.form.lastName)"
        @blur="$v.form.lastName.$touch"
        :error-messages="lastNameErrors"
      ></v-text-field>
      <v-text-field
        :label="$t('users.fields.email.description') + ' *'"
        :placeholder="$t('users.fields.email.title')"
        v-model="form.email"
        @input="delayTouch($v.form.email)"
        @blur="$v.form.email.$touch"
        :error-messages="emailErrors"
      ></v-text-field>
      <v-text-field
        :label="$t('users.fields.phone.description') + ' *'"
        :placeholder="$t('users.fields.phone.title')"
        v-model="form.phone"
        @input="delayTouch($v.form.phone)"
        @blur="$v.form.phone.$touch"
        :error-messages="phoneErrors"
      ></v-text-field>
      <v-select
        :label="$t('users.fields.role.description') + ' *'"
        :placeholder="$t('users.fields.role.title')"
        v-model="form.role"
        @input="delayTouch($v.form.role)"
        @blur="$v.form.role.$touch"
        :error-messages="roleErrors"
        :items="rolesItems"
        :item-text="(val) => $t(val.text)"
      ></v-select>
      <v-checkbox
        color="secondary"
        v-model="form.isActive"
        :label="$t('users.fields.status.description')"
      ></v-checkbox>
      <v-text-field
        :label="$t('users.fields.password.description') + ' *'"
        :placeholder="$t('users.fields.password.title')"
        v-model="form.password"
        @input="delayTouch($v.form.password)"
        @blur="$v.form.password.$touch"
        :error-messages="passwordErrors"
        type="password"
      ></v-text-field>
      <v-text-field
        :label="$t('users.fields.confirmPassword.description') + ' *'"
        :placeholder="$t('users.fields.confirmPassword.title')"
        v-model="form.confirmPassword"
        @input="delayTouch($v.form.confirmPassword)"
        @blur="$v.form.confirmPassword.$touch"
        :error-messages="confirmPasswordErrors"
        type="password"
      ></v-text-field>
      <v-select
        :items="wholesales"
        :loading="loading.wholesale"
        :label="$t('users.fields.wholesale.description')"
        :placeholder="$t('users.fields.wholesale.title')"
        persistent-hint
        :hint="$t('users.fields.wholesale.helper')"
        v-model="form.depots"
        multiple
        chips
      ></v-select>
      <div class="d-flex align-center justify-end mt-5">
        <v-btn type="submit" :loading="loading.save" color="secondary">
          <span class="black--text">
            <span v-if="!user.id">{{ $t("btn.add") }}</span>
            <span v-else>{{ $t("btn.edit") }}</span>
          </span>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import FormDelayTouchMixin from "./../../mixins/commons/form-delay-touch";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  required,
  maxLength,
  helpers,
  email,
  sameAs,
  minLength,
} from "vuelidate/lib/validators";

const defaultForm = {
  username: null,
  password: null,
  confirmPassword: null,
  firstName: null,
  lastName: null,
  role: null,
  email: null,
  phone: null,
  depots: [],
  isActive: true,
};

export default {
  mixins: [FormDelayTouchMixin],
  props: {
    user: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  created() {
    this.initForm();
    this.initWholesales();
  },
  data: () => ({
    form: Vue._.cloneDeep(defaultForm),
    wholesales: [],
    loading: {
      save: false,
      username: false,
      wholesale: false
    },
  }),
  validations() {
    const validators = {
      form: {
        username: {
          required,
          maxLength: maxLength(50),
          async isUnique(value) {
            if (!value) {
              return true;
            }
            if (this.user.username === value) {
              return true;
            }

            this.loading.username = true;
            try {
              await this.request({
                url: "api/v1/users/username/exists",
                method: "post",
                data: { username: this.form.username },
              });
              this.loading.username = false;
              return false;
            } catch (error) {
              this.loading.username = false;
              // empty
              if (error && error.response) {
                if (error.response.status === 404) return true;
              }
              return false;
            }
          },
        },
        firstName: {
          required,
          maxLength: maxLength(50),
        },
        lastName: {
          required,
          maxLength: maxLength(50),
        },
        role: {
          required,
          role: helpers.regex(
            "role",
            /^(ROLE_ADMIN|ROLE_RESELLER|ROLE_DEPOT)$/
          ),
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
          phone: (val) =>
            !helpers.req(val) || this.$utils.validateOperator("all", val),
        },
        confirmPassword: {
          sameAsPassword: sameAs("password"),
        },
        password: {
          minLength: minLength(5),
        },
      },
    };

    if (!this.user.id) {
      validators.form.password.required = required;
    }
    return validators;
  },
  methods: {
    async initWholesales() {
      this.loading.wholesale = true;
      try {
        const response = await this.request({ url: 'api/v1/resellers/depots' });
        const data = response.data;
        const wholesales = [];
        for(const wholesale of data) {
          wholesales.push(wholesale);
        }
        this.wholesales = wholesales;
      } catch (error) {
        // empty
      } 
      this.loading.wholesale = false;
    },
    initForm() {
      if (this.user.id) {
        this.form = {
          username: this.user.username,
          password: null,
          confirmPassword: null,
          firstName: this.user.profile.firstName,
          lastName: this.user.profile.lastName,
          role: this.user.role,
          email: this.user.profile.email,
          phone: this.user.profile.phone,
          depots: this.user.depots,
          isActive: this.user.isActive,
        };
        this.$nextTick(function () {
          this.$v.form.username.$touch();
        });
      }
    },
    async save() {
      let url;
      let method;
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("errors.bad_request") });
        return;
      }

      if (this.user.id) {
        url = `api/v1/users/${this.user.id}`;
        method = "put";
      } else {
        url = "api/v1/users";
        method = "post";
      }

      this.loading.save = true;

      try {
        // eslint-disable-next-line no-unused-vars
        const { type, ...data } = this.form;
        await this.request({
          url: url,
          method: method,
          data: data,
          messages: {
            400: true,
            201: this.$t("users.add.success"),
            200: this.$t("users.edit.success"),
            500: true,
          },
        });

        if (!this.user.id) {
          this.resetForm();
        }
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    resetForm() {
      this.form = Vue._.cloneDeep(defaultForm);
      this.$v.form.$reset();
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  computed: {
    ...mapGetters({ rolesItems: "roles" }),
    usernameErrors() {
      const errors = [];

      if (!this.$v.form.username.$dirty) return errors;

      !this.$v.form.username.required &&
        errors.push(this.$t("users.fields.username.required"));
      !this.$v.form.username.maxLength &&
        errors.push(
          this.$t("users.fields.username.maxLength", {
            max: this.$v.form.username.$params.maxLength.max,
          })
        );
      !this.$v.form.username.isUnique &&
        !this.loading.username &&
        errors.push(this.$t("users.fields.username.already_exist"));

      return errors;
    },
    firstNameErrors() {
      const errors = [];

      if (!this.$v.form.firstName.$dirty) return errors;

      !this.$v.form.firstName.required &&
        errors.push(this.$t("users.fields.firstName.required"));
      !this.$v.form.firstName.maxLength &&
        errors.push(
          this.$t("users.fields.firstName.maxLength", {
            max: this.$v.form.firstName.$params.maxLength.max,
          })
        );

      return errors;
    },
    lastNameErrors() {
      const errors = [];

      if (!this.$v.form.lastName.$dirty) return errors;

      !this.$v.form.lastName.required &&
        errors.push(this.$t("users.fields.lastName.required"));
      !this.$v.form.lastName.maxLength &&
        errors.push(
          this.$t("users.fields.lastName.maxLength", {
            max: this.$v.form.lastName.$params.maxLength.max,
          })
        );

      return errors;
    },
    roleErrors() {
      const errors = [];

      if (!this.$v.form.role.$dirty) return errors;

      !this.$v.form.role.required &&
        errors.push(this.$t("users.fields.role.required"));
      !this.$v.form.role.role &&
        errors.push(this.$t("users.fields.role.invalid"));

      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.form.email.$dirty) return errors;

      !this.$v.form.email.required &&
        errors.push(this.$t("users.fields.email.required"));
      !this.$v.form.email.email &&
        errors.push(this.$t("users.fields.email.invalid"));

      return errors;
    },
    phoneErrors() {
      const errors = [];

      if (!this.$v.form.phone.$dirty) return errors;

      !this.$v.form.phone.required &&
        errors.push(this.$t("users.fields.phone.required"));
      !this.$v.form.phone.phone &&
        errors.push(this.$t("users.fields.phone.invalid"));

      return errors;
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.form.password.$dirty) return errors;

      Object.prototype.hasOwnProperty.call(this.$v.form.password, "required") &&
        !this.$v.form.password.required &&
        errors.push(this.$t("users.fields.password.required"));
      !this.$v.form.password.minLength &&
        errors.push(
          this.$t("users.fields.password.minLength", {
            min: this.$v.form.password.$params.minLength.min,
          })
        );

      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];

      if (!this.$v.form.confirmPassword.$dirty) return errors;
      !this.$v.form.confirmPassword.sameAsPassword &&
        errors.push(this.$t("users.fields.confirmPassword.sameAsPassword"));

      return errors;
    },
  },
};
</script>